import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';

import './public-path';
import actions from './actions';

function render(props) {
  const { container } = props
  const dom = container ? container.querySelector('#root-sub02') : document.getElementById("root-sub02")
  const root = ReactDOM.createRoot(dom)
  root.render(
    <App />
  )
}

if (!window.__POWERED_BY_QIANKUN__) {
  console.log('error');
  render({});
}

export async function bootstrap() {
  console.log('sub02 bootstrap');
}

export async function mount(props) {
  console.log('sub02 mount', props);
  if (props) {
    actions.setActions(props)
  }
  render(props);
}

export async function unmount(props) {
  const { container } = props;
  const dom = container ? container.querySelector('#root-sub02') : document.getElementById("root-sub02")
  const root = ReactDOM.createRoot(dom)
  root.unmount(dom);
}

reportWebVitals();
